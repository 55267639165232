const htmlContainer = document.querySelector('html');
const navbar = document.querySelector('.navbar');
const sticky = navbar.offsetTop + 50;

function stickyNavbar() {
  if (window.scrollY >= sticky) {
    navbar.classList.add('sticky');
    htmlContainer.classList.add('sticky-navbar');
  } else {
    navbar.classList.remove('sticky');
    htmlContainer.classList.remove('sticky-navbar');
  }
}

window.addEventListener('scroll', () => {
  stickyNavbar();
});
