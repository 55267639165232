import $ from 'jquery';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';

let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

function initSelects() {
  const selects = document.querySelectorAll('.ginput_container_select select');

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        render: {
          option: (data, escape) => {
            return (
              '<div><span class="option__label">' +
              escape(data.text) +
              '</span></div>'
            );
          },
        },
      });
    });
  }
}

$(document).on('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  initSelects();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    initSelects();
  }
});
