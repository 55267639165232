// Styled facetwp reset filter

document.addEventListener('facetwp-loaded', function () {
  if (document.querySelector('.facet-reset')) {
    if (document.querySelector('.facetwp-checkbox.checked')) {
      document
        .querySelector('.facet-reset')
        .classList.remove('filter_selected');
    } else {
      document.querySelector('.facet-reset').classList.add('filter_selected');
    }
  }
});
