import domReady from '@wordpress/dom-ready';

domReady(() => {
  const videoStamps = document.querySelectorAll('.video-stamp__text');

  if (0 < videoStamps.length) {
    videoStamps.forEach((text) => {
      text.innerHTML = text.innerText
        .split('')
        .map(
          (char, i) =>
            `<span style="transform:rotate(${i * 10.3}deg)">${char}</span>`
        )
        .join('');
    });
  }
});
