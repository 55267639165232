import domReady from '@wordpress/dom-ready';

//////////////////// ADD ARROW BUTTON ////////////////////
domReady(() => {
  const htmlLinks = document.querySelectorAll(
    '.btn, .btn--outline, .btn--secondary'
  );

  if (0 < htmlLinks.length) {
    htmlLinks.forEach((button) => {
      const linkHtml = button.innerHTML;
      const linkSpan = linkHtml + "<i class='icon-arrow-left'></i>";
      button.innerHTML = linkSpan;
    });
  }

  const items = document.querySelectorAll('.item__with__button-hover');

  if (0 < items.length) {
    items.forEach((item) => {
      item.addEventListener('mouseenter', function () {
        this.classList.add('item-hover-button__active');
        this.querySelector('.item-hover-button').classList.add('active');
      });

      item.addEventListener('mouseleave', function () {
        this.classList.remove('item-hover-button__active');
        this.querySelector('.item-hover-button').classList.remove('active');
      });

      item.addEventListener('mousemove', function (e) {
        const rect = document
          .querySelector('.item-hover-button__active')
          .getBoundingClientRect();

        this.querySelector('.item-hover-button').style.left =
          e.clientX - rect.left + 'px';
        this.querySelector('.item-hover-button').style.top =
          e.clientY - rect.top + 'px';
      });
    });
  }
});
